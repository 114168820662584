import React from 'react'
import './Sidebar.css'

import User from './User'
import AwardList from './AwardList'
import Talkie from './Talkie'



const Sidebar = () => {
  return (
    <aside>
      <div>
        <User />
      </div>
      <AwardList />
      <Talkie />
    </aside>
  )
}
export default Sidebar