import React from 'react'
import './User.css'

const User = () => {
  return(
    <div className="user">
      <span className="icon">WH</span>
      <div>
        <span className="firstname">William</span>
        <span className="grade">Kindergarten</span>
      </div>
      <hr />
    </div>
  )
}
export default User