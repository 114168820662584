import React from 'react'
import './App.css'

import SideBar from './components/Sidebar'
import Main from './components/Main'

const kgreading = [
  {id:0, say: 'The cat ran to the mat.'},
  {id:1, say: '“Jen has a red hat,” said Matt.'},
  {id:2, say: 'They pet the big T-Rex.'},
  {id:3, say: 'They were at the shop. They had some fish and chips.'},
  {id:4, say: 'Beth has many cats. One of the cats is red. The other two cats are black.'},
  {id:5, say: 'There were a lot of people at the zoo. Mom said we could see the bats and big cats.'}
]


function App() {
  return (
    <div className="app">
      <SideBar />
      <Main />
    </div>
  );
}

export default App
