import React from 'react'
import './Main.css'

const Main = () => {
  return(
    <main>
      <div id='say'>Hello William.</div>
    </main>
  )
}
export default Main